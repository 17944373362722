<template>
  <ctk-dialog
    :value="dialogValue"
    modal-class="invite-member-dialog"
    hide-header
    hide-footer
    persistent
    max-width="480px"
    @handle-close="close"
  >
    <div class="invite-member-dialog__wrapper">
      <div class="invite-member-dialog__header tw-h-12 tw-flex tw-justify-end">
        <ctk-dialog-close-button
          data-test="close"
          @click.native="close"
        />
      </div>

      <div
        class="invite-member-dialog__content tw-px-4 tw-pb-6 md:tw-pb-8 md:tw-px-10 tw-flex tw-flex-col tw-pt-0"
      >
        <h1
          v-text="$t('account.members.buttons.invite')"
          class="invite-member-dialog__content__title-form tw-font-normal tw-mb-10 tw-text-2xl"
          data-test="title"
        />

        <ValidationObserver
          ref="observer"
          slim
        >
          <form
            :disabled="$wait.is('inviting user')"
            class="tw-flex tw-flex-col"
            data-test="form"
            @submit.prevent="submitted"
          >
            <ValidationProvider
              ref="email-provider"
              :name="$t('app.fields.email')"
              rules="required|email"
              slim
            >
              <template slot-scope="{ invalid, validated, errors }">
                <ctk-input-text
                  id="email"
                  v-model="formData.email"
                  :label="$t('app.labels.email')"
                  :disabled="$wait.is('inviting user')"
                  :error="invalid && validated"
                  :hint="errors && errors[0]"
                  :has-required-asterisk="false"
                  data-test="email-field"
                  type="email"
                  name="email"
                  class="tw-mb-6"
                  autocomplete="email"
                  required
                />
              </template>
            </ValidationProvider>

            <ui-button
              :loading="$wait.is('inviting user')"
              :disabled="$wait.is('inviting user')"
              class="tw-mb-10 tw-mx-auto"
              variant="primary"
              type="submit"
              data-test="submit"
            >
              {{ $t('account.members.buttons.send_invite') }}
            </ui-button>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </ctk-dialog>
</template>

<script>
  import { defineComponent, ref, watch } from '@vue/composition-api'

  import useModelGetterSetter from '@/composables/useModelGetterSetter'
  import useWait from '@/composables/useWait'
  import useI18n from '@/composables/useI18n'
  import useStore from '@/composables/useStore'

  import CtkDialog from '@/components/CtkDialog/index.vue'
  import CtkDialogCloseButton from '@/components/CtkDialog/_subs/CtkDialogCloseButton/index.vue'
  import CtkInputText from '@/components/CtkInputs/CtkInputText/index.vue'
  import handlePropertyPathViolations from '@/resources/handlers/violations'
  import { showToaster } from '@/services/Toaster'

  /**
   * @module component - InviteMemberDialog
   */
  export default defineComponent({
    name: 'InviteMemberDialog',
    components: {
      CtkDialog,
      CtkInputText,
      CtkDialogCloseButton
    },
    props: {
      value: {
        type: Boolean,
        required: true
      }
    },
    setup (props, { refs, root }) {
      const wait = useWait()
      const i18n = useI18n()
      const store = useStore()
      const { state: dialogValue } = useModelGetterSetter(props, 'value')

      const formData = ref({
        email: null
      })

      watch(dialogValue, (v) => {
        if (!v) {
          formData.value.email = null
        }
      })

      function submitted () {
        // @ts-ignore
        refs.observer.validate()
          .then((/** @type {boolean} */ valid) => {
            if (!valid) return

            if (wait.is('inviting user')) return

            wait.start('inviting user')
            store.value.dispatch('account/requestInvite', { email: formData.value.email })
              .then(() => {
                console.log('ok?')
                close()

                showToaster(null, i18n.value.t('account.members.paragraphs.invite_sent'), {
                  type: 'success',
                  position: 'bottom-right'
                })
              })
              // @ts-ignore
              .catch(err => {
                if (!err.response) return

                const { data } = err.response
                if (data && data.error && data.error.violations) {
                  handlePropertyPathViolations.call(root, data.error.violations, refs)
                } else {
                  showToaster(null, data.error.title, {
                    type: 'error',
                    position: 'bottom-right'
                  })
                }
              })
              .finally(() => {
                wait.end('inviting user')
              })
          })
      }

      function close () {
        dialogValue.value = false
      }

      return {
        formData,
        close,
        submitted,
        dialogValue
      }
    }
  })
</script>

<style lang="scss">
.invite-member-dialog .modal-container {
  width: 100%;
}
.invite-member-dialog__content__title-form {
  position: relative;
}
.invite-member-dialog__content__title-form::after {
  position: absolute;
  content: '';
  left: 0;
  bottom: -0.5rem;
  width: 50%;
  height: 1px;
  background-color: $divider;
}
.invite-member-dialog__footer {
  margin-top: 32px;
}
.invite-member-dialog__wrapper {
  position: relative;
}
@media (min-width: 770px) {
  .invite-member-dialog__wrapper {
    padding-bottom: 170px;
  }
  .invite-member-dialog__wrapper::after {
    position: absolute;
    bottom: 16px;
    width: 100%;
    min-height: 170px;
    content: '';
    background-size: cover;
    background-image: url('~@/assets/img/illustrations/shipment_confirmation.svg');
    background-position: center bottom;
    opacity: 0.8;
  }
}
</style>
