<template>
  <div
    class="members"
    v-infinite-scroll="loadMore"
  >
    <div class="tw-flex tw-justify-between tw-items-end tw-border-0 tw-border-solid tw-mb-12 tw-border-b tw-border-gray-900">
      <h2
        v-text="$t('account.members.title')"
        class="members__title tw-text-xl tw-mb-0"
        data-test="title"
      />

      <ui-button
        v-if="isOwner"
        variant="link"
        data-test="invite-button"
        @click="dialogs.invite = true"
      >
        <template #left-icon>
          <ui-material-icon
            name="add_circle"
            data-test="icon"
          />
        </template>
        <span
          v-text="$t('account.members.buttons.invite')"
          data-test="content"
        />
      </ui-button>
    </div>

    <div class="members__list">
      <template
        v-if="$wait.is('fetching users')"
      >
        <div
          v-for="(v, k) in Array.from(new Array(3))"
          :key="k"
          class="tw-flex tw-items-center tw-mb-5"
          data-test="loading"
        >
          <ctk-skeleton-block
            width="40px"
            height="40px"
            level="2"
            class="tw-mr-5"
            loading
          />
          <div class="tw-flex tw-flex-col">
            <ctk-skeleton-block
              width="100px"
              height="20px"
              level="2"
              class="tw-mb-2"
              loading
            />
            <ctk-skeleton-block
              width="180px"
              height="20px"
              level="2"
              loading
            />
          </div>
        </div>
      </template>
      <template
        v-else
      >
        <invite-item
          v-for="invite in invites"
          :key="invite.uuid"
          :user="invite"
          class="tw-mb-3 tw-pb-3"
          data-test="item"
        />
        <member-item
          v-for="user in users"
          :key="user.uuid"
          :user="user"
          class="tw-mb-3 tw-pb-3"
          data-test="item"
          @remove="remove(user)"
        />
      </template>
    </div>

    <!-- Dialogs -->
    <invite-member-dialog
      v-model="dialogs.invite"
    />

    <remove-member-dialog
      v-model="dialogs.remove.visible"
      :user="dialogs.remove.user"
    />
  </div>
</template>

<script>
  import { defineComponent, onMounted, computed, reactive } from '@vue/composition-api'

  import store from '@/store'

  import useWait from '@/composables/useWait'
  import useStore from '@/composables/useStore'

  import CtkSkeletonBlock from '@/components/CtkSkeletonView/_subs/CtkSkeletonBlock/index.vue'
  import MemberItem from './components/MemberItem/index.vue'
  import InviteItem from './components/InviteItem/index.vue'
  import InviteMemberDialog from './components/InviteMemberDialog/index.vue'
  import RemoveMemberDialog from './components/RemoveMemberDialog/index.vue'

  /**
   * @module view - Members
   */
  export default defineComponent({
    name: 'Members',
    components: {
      MemberItem,
      InviteItem,
      CtkSkeletonBlock,
      InviteMemberDialog,
      RemoveMemberDialog
    },
    metaInfo () {
      return {
        title: this.$t('account.members.title')
      }
    },
    setup () {
      const wait = useWait()
      const store = useStore()

      /**
       * @type {{
       *   invite: boolean,
       *   remove: {
       *    visible: boolean,
       *    user: Object|null
       *   }
       * }}
       */
      const dialogs = reactive({
        invite: false,
        remove: {
          visible: false,
          user: null
        }
      })

      const users = computed(() => store.value.getters['account/getUsers'])
      const invites = computed(() => store.value.getters['account/getInvites'])
      const isOwner = computed(() => store.value.getters['auth/isCompanyOwner'])

      async function fetchUsers () {
        wait.start('fetching users')

        /**
         * By awaiting for both requests, we avoid the list jumping since we have two
         * separate requests with two different datas to show.
         */
        try {
          await store.value.dispatch('account/retrieveUsers')
          if (isOwner.value) await store.value.dispatch('account/retrieveUserInvites')
        } catch (e) { /* noop */ }

        wait.end('fetching users')
      }

      async function loadMore () {
        wait.start('fetching more users')

        await store.value.dispatch('account/retrieveMoreUsers')
        if (isOwner.value) await store.value.dispatch('account/retrieveMoreUserInvites')

        wait.end('fetching more users')
      }

      /**
       * @function remove
       * @param {Object} user
       */
      function remove (user) {
        dialogs.remove.user = user
        dialogs.remove.visible = true
      }

      onMounted(() => {
        fetchUsers()
      })

      return {
        remove,
        dialogs,
        isOwner,
        users,
        invites,
        fetchUsers,
        loadMore
      }
    },
    // @ts-ignore
    beforeRouteEnter (to, from, next) {
      store.dispatch('setAppReady', true)

      if (!store.getters.isUserShipper) {
        next({
          name: 'AccountProfile'
        })
        return
      }

      next()
    }
  })
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 857px) {
  .members__title {
    display: none;
  }
}
.members__list .member-item, .members__list .invite-item {
  border-bottom: 1px solid $divider;
}
.members__list {
  padding-top: 0.75rem;
  border-top: 1px solid $divider;
}
</style>
