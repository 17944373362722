<template>
  <div
    :data-id="user.uuid"
    class="member-item tw-flex tw-items-center"
  >
    <ctk-avatar
      :user="user"
      class="tw-mr-5 tw-flex-fixed"
      data-test="avatar"
    />
    <div class="tw-flex-1 tw-flex tw-flex-col tw-leading-tight tw-truncate">
      <div
        v-text="name"
        class="tw-text-base tw-font-medium tw-truncate"
        data-test="name"
      />
      <hover-on-croppable
        :value="user.email"
        class="tw-text-sm tw-text-secondary-text tw-truncate"
        data-test="email"
      />
    </div>
    <div class="member-item__status-container tw-flex tw-justify-end tw-ml-2">
      <div
        v-if="user.is_owner"
        class="tw-rounded-full tw-text-secondary-lighten tw-bg-gray-500 tw-px-2 tw-py-1 tw-text-xs tw-my-auto"
        v-text="$t('account.members.labels.admin')"
        data-test="status"
      />

      <ui-button
        v-if="isCompanyOwner && !user.is_owner && hasRemove"
        v-b-tooltip.hover
        :title="$t('account.members.labels.remove')"
        variant="link"
        size="sm"
        data-test="delete-button"
        @click="remove"
      >
        <template #left-icon>
          <ui-ctk-icon
            name="trash"
            data-test="icon"
          />
        </template>
      </ui-button>
    </div>
  </div>
</template>

<script>
  import { defineComponent, toRefs, computed } from '@vue/composition-api'

  import CtkAvatar from '@/components/CtkAvatar/index.vue'
  import HoverOnCroppable from '@/components/UI/HoverOnCroppable/index.vue'

  import useStore from '@/composables/useStore'

  /**
   * @module component - MemberItem
   * @param {Object} user - The user object
   * @param {String} user.uuid - The user UUID
   * @param {String} user.first_name - The user first name
   * @param {String} user.last_name - The user last name
   */
  export default defineComponent({
    components: {
      CtkAvatar,
      HoverOnCroppable
    },
    props: {
      user: {
        type: Object,
        required: true
      },
      hasRemove: {
        type: Boolean,
        default: true
      }
    },
    emits: ['remove'],
    setup (props, { emit }) {
      const { user } = toRefs(props)
      const store = useStore()

      const name = computed(() => {
        const { first_name: firstName, last_name: lastName } = user.value
        return `${firstName} ${lastName}`
      })
      const isCompanyOwner = computed(() => store.value.getters['auth/isCompanyOwner'])

      function remove () {
        emit('remove')
      }

      return {
        name,
        isCompanyOwner,
        remove
      }
    }
  })
</script>

<style lang="scss" scoped>

  .member-item__status-container {
    min-width: 100px;
  }

</style>
